import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";

export default function LoadingPage() {
  return (
    <MKBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f4f1ec",
      }}
    >
      <CircularProgress
        size={100}
        sx={{
          color: "#f3661e",
        }}
      />
    </MKBox>
  );
}
