import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import StoryCard from "./StoryCard";
import AWS from "aws-sdk";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

const lambda = new AWS.Lambda();

async function downloadStoryUsingLambda(storyId) {
  const params = {
    FunctionName: "DownloadWordSoupStoryFromS3", // Your Lambda function name
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ body: storyId }),
  };

  try {
    const result = await lambda.invoke(params).promise();
    const payload = JSON.parse(result.Payload);
    return payload.body;
  } catch (err) {
    console.error("Error");
  }
}

function Discover() {
  const [stories, setStories] = useState([]);
  const [sort, setSort] = useState(0);

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    const downloadStories = async () => {
      const story1 = await downloadStoryUsingLambda("UkcLWCis");
      const story2 = await downloadStoryUsingLambda("qTN6EahQ");
      const story3 = await downloadStoryUsingLambda("Hwr8GoV5");
      const story4 = await downloadStoryUsingLambda("W2ZRyn9m");
      setStories([JSON.parse(story1), JSON.parse(story2), JSON.parse(story3), JSON.parse(story4)]);
    };
    downloadStories();
  }, []);
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <MKBox
        width="80vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" flexDirection="row" sx={{ minWidth: 140, mt: "50px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              label="Sort By"
              onChange={handleChange}
              IconComponent={null}
              sx={{
                height: "30px", // Adjust the height as needed
                "& .MuiInputBase-root": {
                  height: "30px", // Adjust the height as needed
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  height: "100%", // Ensure the content is centered vertically
                },
              }}
            >
              <MenuItem value={0}>New</MenuItem>
              <MenuItem value={1}>Top This Week</MenuItem>
              <MenuItem value={2}>Top This Month</MenuItem>
            </Select>
          </FormControl>
          <ArrowDropDownIcon sx={{ ml: "-30px", mt: "5px" }} />
        </Box>
        <MKTypography variant="h2" fontWeight="bold" mt="60px" mb="30px" align="center">
          {"Recent Stories"}
        </MKTypography>
        {stories.length !== 0 ? (
          <Grid container spacing={3} sx={{ mb: 10 }} key={"stories"} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={0}>
                  <Link to={"/story/" + stories[0].storyId} state={stories[0]}>
                    <StoryCard title={stories[0].title} image={stories[0].image} />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={1}>
                  <Link to={"/story/" + stories[1].storyId} state={stories[1]}>
                    <StoryCard title={stories[1].title} image={stories[1].image} />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={2}>
                  <Link to={"/story/" + stories[2].storyId} state={stories[2]}>
                    <StoryCard title={stories[2].title} image={stories[2].image} />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={3}>
                  <Link to={"/story/" + stories[3].storyId} state={stories[3]}>
                    <StoryCard title={stories[3].title} image={stories[3].image} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress
            size={24}
            sx={{
              color: "white",
              backgroundColor: "transparent",
              "& .MuiCircularProgress-circle": {
                stroke: "blue",
              },
            }}
          />
        )}
      </MKBox>
    </MKBox>
  );
}

export default Discover;
