/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function StoryCard({ title, image }) {
  const imageTemplate = (
    <MKBox
      borderRadius="xl"
      shadow="lg"
      minHeight="7rem"
      sx={{
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(3000px) rotateX(5deg) translate3d(0px, -1px, 10px)",
        },
      }}
    >
      <MKBox
        component="img"
        src={image}
        alt={title}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center",
        }}
        opacity={1}
      />
    </MKBox>
  );
  return (
    <MKBox position="relative">
      {imageTemplate}
      <MKBox mt={1} ml={1} lineHeight={1}>
        <MKTypography variant="h5" color="black">
          {title}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the ExampleCard
StoryCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
};

export default StoryCard;
