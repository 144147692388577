function getRandomInt(max) {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % max;
}

export const randomWord = (prompt) => {
  let words = [];
  if (prompt === "body part") {
    words = [
      "head",
      "face",
      "hair",
      "ear",
      "eye",
      "nose",
      "mouth",
      "arm",
      "hand",
      "finger",
      "leg",
      "feet",
      "toe",
    ];
  } else if (prompt === "name") {
    words = [
      "Cinderella",
      "Pocahontas",
      "Rapunzel",
      "Snow White",
      "Hermonie",
      "Pinocchio",
      "Dumbo",
      "Bambi",
      "Winnie the Pooh",
      "Eeyore",
      "Piglet",
      "Tarzan",
      "Peter Pan",
      "Puss in Boots",
      "Captian Hook",
      "Nancy Drew",
      "Sherlock Holmes",
      "Arthur",
      "Matilda",
      "Mr. Grinch",
      "Frosty the Snowman",
      "Rudolph the Red-Nosed Reindeer",
      "Santa",
      "Taylor Swift",
      "George Washington",
      "Abraham Lincoln",
      "Thomas Jefferson",
      "Benjamin Franklin",
      "Alexander Hamilton",
      "Lin-Manuel Miranda",
      "Jackie Chan",
      "Morgan Freeman",
      "Ellen DeGeneres",
      "Oprah Winfrey",
      "Greta Thunberg",
      "Mr. Rogers",
      "Bill Gates",
      "Bob Ross",
      "Michelle Obama",
      "LeBron James",
      "Simone Biles",
      "Stephen Curry",
    ];
  } else if (prompt === "exclamation") {
    words = [
      "Wow!",
      "Oh no!",
      "Good grief!",
      "Yikes!",
      "Oops!",
      "Eek!",
      "Aha!",
      "Phew!",
      "Huh?",
      "Gee!",
      "Gosh!",
      "Ugh!",
      "Yay!",
      "Bang!",
      "Bam!",
      "Boo!",
      "Brr!",
      "Biscuits!",
      "Bingo!",
      "Blast!",
      "Bless you!",
      "Blimey!",
      "Bravo!",
      "Goodness!",
      "Great Scott!",
      "Heavens!",
      "Holy cow!",
      "Holy smokes!",
      "Holy mackerel!",
      "Holy guacamole!",
      "Holy moly!",
    ];
  } else if (prompt === "number") {
    words = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
      "ninety-nine",
      "one hundred",
      "one hundred and one",
      "one hundred and ten",
      "two hundred",
      "one thousand",
      "ten thousand",
      "one million",
      "ten million",
      "one billion",
      "ten billion",
      "a gazillion",
    ];
  } else if (prompt === "noun") {
    words = [
      "apple",
      "banana",
      "cherry",
      "grape",
      "strawberry",
      "blueberry",
      "lemon",
      "pencil",
      "pen",
      "book",
      "chair",
      "table",
      "computer",
      "phone",
      "car",
      "bike",
      "shirt",
      "pants",
      "shoe",
      "sock",
      "hat",
      "clock",
      "backpack",
      "purse",
      "wallet",
      "key",
      "door",
      "window",
      "floor",
      "wall",
      "roof",
      "ceiling",
      "bed",
      "pillow",
      "blanket",
      "sheet",
      "towel",
      "soap",
      "shampoo",
      "toothbrush",
      "toothpaste",
      "stove",
      "oven",
      "microwave",
      "refrigerator",
      "sink",
      "blender",
      "napkin",
      "plate",
      "bowl",
      "fork",
      "knife",
      "spoon",
      "cup",
      "letter",
      "paper",
      "carrot",
      "broccoli",
      "lettuce",
      "cucumber",
      "salad",
      "potato",
      "tomato",
      "onion",
      "butter",
      "milk",
      "cheese",
      "egg",
      "bread",
      "water",
      "juice",
      "tree",
      "flower",
      "dog",
      "cat",
      "cow",
      "horse",
      "sheep",
      "goat",
      "pig",
      "chicken",
      "rabbit",
      "duck",
      "elephant",
      "bear",
      "wolf",
      "deer",
      "monkey",
      "shark",
      "turtle",
      "man",
      "woman",
      "child",
      "world",
      "dog",
      "cat",
      "cow",
      "horse",
      "sheep",
      "goat",
      "pig",
      "chicken",
      "rabbit",
      "duck",
      "elephant",
      "bear",
      "wolf",
      "deer",
      "monkey",
      "shark",
      "turtle",
      "water",
      "food",
      "money",
      "family",
      "friend",
      "school",
      "student",
      "teacher",
      "house",
      "apartment",
      "room",
      "floor",
      "wall",
      "door",
      "window",
      "ceiling",
      "roof",
      "bed",
      "sofa",
      "chair",
      "table",
      "shelf",
      "lamp",
      "rug",
      "closet",
      "mirror",
      "drawer",
      "shower",
      "sink",
      "toilet",
      "towel",
      "clock",
      "pillow",
      "blanket",
      "sheet",
      "light",
      "fan",
      "heater",
      "remote",
      "soap",
      "shampoo",
      "toothbrush",
      "toothpaste",
      "brush",
      "comb",
      "scissors",
      "laundry",
      "broom",
      "mop",
      "vacuum",
      "candle",
      "bucket",
      "sponge",
      "bag",
      "bread",
      "milk",
      "egg",
      "cheese",
      "butter",
      "rice",
      "pasta",
      "cereal",
      "juice",
      "coffee",
      "tea",
      "water",
      "soda",
      "salad",
      "fruit",
      "vegetable",
      "apple",
      "banana",
      "orange",
      "grape",
      "carrot",
      "potato",
      "onion",
      "garlic",
      "lemon",
      "lime",
      "meat",
      "chicken",
      "beef",
      "pork",
      "fish",
      "salt",
      "sugar",
      "oil",
      "vinegar",
      "sauce",
      "pepper",
      "honey",
      "chocolate",
      "candy",
      "cake",
      "pie",
      "sandwich",
      "soup",
      "pizza",
      "burger",
      "ice cream",
      "cookie",
      "snack",
      "breakfast",
      "lunch",
      "dinner",
      "plate",
      "bowl",
      "cup",
      "glass",
      "mug",
      "fork",
      "spoon",
      "pan",
      "pot",
      "oven",
      "stove",
      "microwave",
      "refrigerator",
      "blender",
      "toaster",
      "napkin",
      "straw",
      "jar",
      "freezer",
      "basket",
      "shirt",
      "dress",
      "skirt",
      "sweater",
      "jacket",
      "coat",
      "sock",
      "shoe",
      "boot",
      "sandal",
      "hat",
      "scarf",
      "belt",
      "blouse",
      "T-shirt",
      "raincoat",
      "swimsuit",
      "purse",
      "wallet",
      "backpack",
      "ring",
      "bracelet",
      "necklace",
      "earring",
      "watch",
      "umbrella",
      "pocket",
      "button",
      "shoelace",
      "glove",
      "bonnet",
      "robe",
      "sky",
      "sun",
      "moon",
      "star",
      "cloud",
      "rain",
      "snow",
      "wind",
      "storm",
      "tree",
      "flower",
      "grass",
      "leaf",
      "plant",
      "bush",
      "forest",
      "jungle",
      "desert",
      "beach",
      "ocean",
      "river",
      "lake",
      "mountain",
      "hill",
      "rock",
      "stone",
      "dirt",
      "sand",
      "mud",
      "island",
      "volcano",
      "water",
      "fire",
      "wood",
      "wave",
      "cave",
      "waterfall",
      "rainbow",
      "dog",
      "cat",
      "bird",
      "fish",
      "cow",
      "sheep",
      "pig",
      "mouse",
      "horse",
      "lion",
      "tiger",
      "bear",
      "monkey",
      "elephant",
      "giraffe",
      "wolf",
      "fox",
      "rabbit",
      "frog",
      "bee",
      "butterfly",
      "deer",
      "eagle",
      "owl",
      "duck",
      "chicken",
      "lamb",
      "goat",
      "turtle",
      "dolphin",
      "whale",
      "shark",
      "octopus",
      "crab",
      "lobster",
      "squirrel",
      "bat",
      "rat",
      "penguin",
      "goose",
      "camel",
      "kangaroo",
      "parrot",
      "panda",
      "car",
      "bike",
      "bus",
      "train",
      "airplane",
      "boat",
      "scooter",
      "truck",
      "van",
      "subway",
      "taxi",
      "road",
      "street",
      "highway",
      "bridge",
      "tunnel",
      "path",
      "airport",
      "garage",
      "parking",
      "map",
      "compass",
      "luggage",
      "ticket",
      "passport",
      "visa",
      "suitcase",
      "backpack",
      "fuel",
      "engine",
      "license",
      "seat",
      "wheel",
      "horn",
      "sidewalk",
      "computer",
      "laptop",
      "phone",
      "tablet",
      "screen",
      "keyboard",
      "printer",
      "charger",
      "battery",
      "cable",
      "speaker",
      "headphone",
      "camera",
      "microphone",
      "remote",
      "TV",
      "app",
      "robot",
      "message",
      "call",
      "text",
      "selfie",
      "picture",
      "book",
      "notebook",
      "paper",
      "pen",
      "pencil",
      "eraser",
      "tape",
      "scissors",
      "ruler",
      "calculator",
      "dictionary",
      "map",
      "calendar",
      "schedule",
      "desk",
      "chair",
      "stamp",
      "mail",
      "letter",
      "card",
      "alarm",
      "printer",
    ];
  } else if (prompt === "plural noun") {
    words = [
      "apples",
      "bananas",
      "grapes",
      "lemons",
      "pencils",
      "pens",
      "books",
      "chairs",
      "tables",
      "computers",
      "phones",
      "cars",
      "bikes",
      "shirts",
      "pants",
      "shoes",
      "socks",
      "hats",
      "glasses",
      "clocks",
      "backpacks",
      "purses",
      "wallets",
      "keys",
      "doors",
      "windows",
      "floors",
      "walls",
      "roofs",
      "ceilings",
      "beds",
      "pillows",
      "blankets",
      "towels",
      "soaps",
      "shampoos",
      "plates",
      "bowls",
      "forks",
      "spoons",
      "cups",
      "letters",
      "papers",
      "carrots",
      "cucumbers",
      "salads",
      "potatos",
      "tomatoes",
      "onions",
      "eggs",
      "trees",
      "flowers",
      "students",
      "teachers",
      "houses",
      "apartments",
      "rooms",
      "floors",
      "walls",
      "doors",
      "windows",
      "ceilings",
      "roofs",
      "beds",
      "sofas",
      "chairs",
      "tables",
      "shelves",
      "lamps",
      "rugs",
      "closets",
      "mirrors",
      "drawers",
      "showers",
      "sinks",
      "toilets",
      "towels",
      "clocks",
      "pillows",
      "blankets",
      "lights",
      "fans",
      "heaters",
      "remotes",
      "soaps",
      "shampoos",
      "toothbrushes",
      "toothpastes",
      "brushes",
      "brooms",
      "mops",
      "vacuums",
      "candles",
      "buckets",
      "sponges",
      "bags",
      "breads",
      "milks",
      "eggs",
      "cheeses",
      "butters",
      "rices",
      "pastas",
      "cereals",
      "juices",
      "coffees",
      "teas",
      "waters",
      "sodas",
      "salads",
      "fruits",
      "vegetables",
      "apples",
      "bananas",
      "oranges",
      "grapes",
      "carrots",
      "potatoes",
      "onions",
      "garlics",
      "lemons",
      "limes",
      "meats",
      "chickens",
      "beefs",
      "porks",
      "salts",
      "sugars",
      "oils",
      "vinegars",
      "sauces",
      "peppers",
      "honeys",
      "chocolates",
      "candies",
      "cakes",
      "pies",
      "sandwiches",
      "soups",
      "pizzas",
      "burgers",
      "ice creams",
      "cookies",
      "snacks",
      "plates",
      "bowls",
      "cups",
      "glasses",
      "mugs",
      "forks",
      "spoons",
      "pans",
      "pots",
      "ovens",
      "stoves",
      "microwaves",
      "refrigerators",
      "blenders",
      "toasters",
      "napkins",
      "straws",
      "jars",
      "freezers",
      "baskets",
      "shirts",
      "pants",
      "jeans",
      "shorts",
      "dresses",
      "skirts",
      "sweaters",
      "jackets",
      "coats",
      "socks",
      "shoes",
      "boots",
      "sandals",
      "hats",
      "scarves",
      "belts",
      "blouses",
      "T-shirts",
      "raincoats",
      "swimsuits",
      "sunglasses",
      "purses",
      "wallets",
      "backpacks",
      "rings",
      "bracelets",
      "necklaces",
      "earrings",
      "watches",
      "umbrellas",
      "pockets",
      "buttons",
      "shoelaces",
      "gloves",
      "bonnets",
      "robes",
      "skies",
      "suns",
      "moons",
      "stars",
      "clouds",
      "rains",
      "snows",
      "winds",
      "storms",
      "trees",
      "flowers",
      "grasses",
      "leaves",
      "plants",
      "bushes",
      "forests",
      "jungles",
      "deserts",
      "beaches",
      "oceans",
      "rivers",
      "lakes",
      "mountains",
      "hills",
      "rocks",
      "stones",
      "sands",
      "muds",
      "islands",
      "volcanoes",
      "waters",
      "fires",
      "woods",
      "waves",
      "caves",
      "waterfalls",
      "rainbows",
      "dogs",
      "cats",
      "birds",
      "fishes",
      "cows",
      "sheep",
      "pigs",
      "mice",
      "horses",
      "lions",
      "tigers",
      "bears",
      "monkeys",
      "elephants",
      "giraffes",
      "wolves",
      "foxes",
      "rabbits",
      "frogs",
      "bees",
      "butterflies",
      "deer",
      "eagles",
      "owls",
      "ducks",
      "chickens",
      "lambs",
      "goats",
      "turtles",
      "dolphins",
      "whales",
      "sharks",
      "octopuses",
      "crabs",
      "lobsters",
      "squirrels",
      "bats",
      "rats",
      "penguins",
      "geese",
      "camels",
      "kangaroos",
      "parrots",
      "pandas",
      "cars",
      "bikes",
      "buses",
      "trains",
      "airplanes",
      "boats",
      "scooters",
      "trucks",
      "vans",
      "subways",
      "taxis",
      "roads",
      "streets",
      "highways",
      "bridges",
      "tunnels",
      "paths",
      "airports",
      "garages",
      "maps",
      "compasses",
      "tickets",
      "passports",
      "visas",
      "suitcases",
      "backpacks",
      "engines",
      "licenses",
      "seats",
      "trains",
      "cars",
      "trucks",
      "laptops",
      "phones",
      "tablets",
      "screens",
      "keyboards",
      "printers",
      "chargers",
      "batteries",
      "cables",
      "speakers",
      "headphones",
      "cameras",
      "microphones",
      "remotes",
      "robots",
      "selfies",
      "books",
      "notebooks",
      "papers",
      "pens",
      "pencils",
      "erasers",
      "tapes",
      "scissors",
      "rulers",
      "calculators",
      "dictionaries",
      "maps",
      "calendars",
      "schedules",
      "desks",
      "chairs",
      "stamps",
      "mails",
      "letters",
      "cards",
      "alarms",
      "printers",
    ];
  } else if (prompt === "verb") {
    words = [
      "run",
      "walk",
      "give",
      "take",
      "eat",
      "drink",
      "sleep",
      "say",
      "think",
      "bring",
      "send",
      "buy",
      "sell",
      "read",
      "write",
      "draw",
      "paint",
      "sing",
      "dance",
      "hug",
      "kiss",
      "cry",
      "laugh",
      "smile",
      "puke",
      "sneeze",
      "help",
      "drive",
      "fly",
      "swim",
      "travel",
      "play",
      "work",
      "study",
      "learn",
      "teach",
      "cook",
      "clean",
      "shower",
      "dress",
      "move",
      "wear",
      "use",
      "watch",
      "listen",
      "talk",
      "text",
      "call",
      "see",
      "hear",
      "feel",
      "smell",
      "taste",
    ];
  } else if (prompt === "adjective") {
    words = [
      "big",
      "small",
      "red",
      "blue",
      "green",
      "yellow",
      "orange",
      "purple",
      "gray",
      "brown",
      "pink",
      "happy",
      "sad",
      "angry",
      "tired",
      "hungry",
      "thirsty",
      "cold",
      "fast",
      "slow",
      "loud",
      "quiet",
      "clean",
      "nice",
      "mean",
      "good",
      "bad",
      "beautiful",
      "smart",
      "interesting",
      "boring",
      "funny",
      "scary",
      "cute",
      "cool",
      "awesome",
    ];
  } else {
    words = ["banana", "bello", "bee do bee do", "bapanada", "papoy"];
  }
  const len = words.length;
  const random = getRandomInt(len);
  return words[random];
};

export const randomTitle = () => {
  let titles = [];
  titles = [
    "The 80s",
    "The 90s",
    "The 2000s",
    "The 2010s",
    "The Future",
    "Love Letter",
    "Letter from Abraham Lincoln",
    "The Secret to Happiness",
    "The Secret to Success",
    "Wizard School",
    "A Trip to Space",
    "Swimming in the Ocean",
    "A Day in the Mountains",
    "A Day in the Woods",
    "A Day in the Mountains",
    "A Day at the Lake",
    "A Day at the River",
    "A Day at the Farm",
    "A Day in the Amazon Rainforest",
    "A Day at the Beach",
    "A Day at the Park",
    "A Day at the Zoo",
    "A Trip to the Aquarium",
    "A Trip to the Museum",
    "A Trip to the Library",
    "A Trip the Bookstore",
    "A Trip to the Movie Theater",
    "A Trip to the Mall",
    "The First Halloween",
    "The First Christmas",
    "The First Thanksgiving",
    "The First New Year's",
    "The First Valentine's Day",
    "The First Easter",
    "The First Fourth of July",
    "The First St. Patrick's Day",
    "The First Mother's Day",
    "The First Father's Day",
    "The First Day of School",
    "The First Day of Summer",
    "My Crazy Slumber Party",
    "The Crazy Birthday Party",
    "My Snowman Friend",
    "The Halloween Snowman",
    "The Thanksgiving Snowman",
    "The Valentines Day Snowman",
    "The Crazy Snowball Fight",
    "April Fool's Day",
    "Haunted House",
    "The Great Pegasus Games",
    "The Unicorn Games",
    "Bigfoot Sports",
    "The Bigfoot Games",
    "The Snowman Games",
    "Mermaid Sports",
    "The Great Skeleton Games",
    "The Dream Games",
    "Fairy Sports",
    "The Great Fairy Games",
    "The Great Fairy Games",
    "Fairy Music",
    "The Wolf Games",
    "Wolf Sports",
    "Wolf Music",
    "Wizard School in Paris",
    "Wizard School in New York",
    "Wizard School in Tokyo",
    "Wizard School in London",
    "Wizard School in Rome",
    "Wizard School in Venice",
    "Wizard School in Rio",
    "Wizard School in Toronto",
    "Wizard School in San Francisco",
    "Wizard School on Mount Everest",
    "Wizard School in the Amazon Rainforest",
    "Wizard School in the Arctic Circle",
    "Wizard School in Stockholm",
    "Wizard School in Tokyo",
    "The Great Dragon Games",
    "The Fashion Olympics",
    "The Food Olympics",
    "The Vacation Olympics",
    "The Mystery Olympics",
    "The Magic Olympics",
    "The Wizard Olympics",
    "The Witch Olympics",
    "The Great Giant Games",
    "The Fairy Tale Olympics",
    "The Monster Olympics",
    "The Superhero Olympics",
    "The Princess Olympics",
    "The Prince Olympics",
    "The Knight Games",
    "The Great Pirate Games",
    "The Ninja Olympics",
    "The Space Olympics",
    "The Crazy Birthday",
    "The Crazy Christmas",
    "The Crazy Thanksgiving",
    "The Crazy New Year's",
    "The Crazy Valentine's Day",
    "The Crazy Easter",
    "The Crazy Fourth of July",
    "The Crazy St. Patrick's Day",
    "The Crazy Mother's Day",
    "The Crazy Father's Day",
    "How to Be a Pop Star",
    "The Pop Star's Hit Song",
    "Pop Star's World Tour",
    "The Pop Star's Secret",
    "The Pop Star's Pet",
    "The Pop Star's Mansion",
    "The Pop Star's Fashion Line",
    "The Pop Star's Movie",
    "The Pop Star's TV Show",
    "The Pop Star's Book",
    "The Pop Star's Album",
    "The Pop Star's Concert",
    "The Pop Star's Music Video",
    "The Pop Star's Dance",
    "Doggy Daycare",
    "Camping in the Woods",
    "Camping in the Mountains",
    "Camping in the Snow",
    "Camping in Space",
    "Camping in the Rain",
    "Hiking in the Rain",
    "Biking in the Rain",
    "Skiing in the Rain",
    "Making S'mores",
    "The Campfire Story",
    "The Scary Story",
    "The Campfire Dance",
    "The Campfire Song",
    "The Campfire Game",
    "The Campfire Cookout",
    "The Campfire Marshmallows",
    "The Fishing Trip",
    "The Hiking Trail",
    "The Mountain Climber",
    "The Rock Climber",
    "The Climbing Wall",
    "The Climbing Competition",
    "The Bigfoot Sighting",
    "The Bookstore Ghost",
    "The Haunted Graveyard",
    "The Haunted House",
    "The Haunted Mansion",
    "The Haunted Hotel",
    "My Neighbor is a Vampire",
    "The Ghost Train",
    "The Ghost Town",
    "Potions Class",
    "A History of Magic",
    "Broomstick Flying Class",
    "The Forbidden Forest",
    "The Quidditch Match",
    "Winnie the Pooh",
    "The Hundred Acre Wood",
    "My Pet Dragon",
    "My Pet Unicorn",
    "Monster Hotel",
    "Fairytale Land",
    "The Princess and the Pea",
    "The Frog Prince",
    "The Little Mermaid",
    "Little Red Riding Hood",
    "Goldilocks and the Three Bears",
    "Hansel and Gretel",
    "Rumpelstiltskin",
    "The Pied Piper",
    "The Floating Castle",
    "The Living Castle",
    "The Castle in the Sky",
    "The Castle in the Sea",
    "The Castle in the Forest",
    "The Castle in the Desert",
    "The Castle in the Jungle",
    "The Castle in the Mountains",
    "The Castle in the Valley",
    "The Castle in the River",
    "The Castle in the Lake",
    "The Castle in the Ocean",
    "The Castle in the Cave",
    "The Castle in the Volcano",
    "The Castle in the Swamp",
    "The Castle in the Snow",
    "The Castle in the Ice",
    "The Castle in the Rain",
    "The Castle in the Sun",
    "The Castle in the Moon",
    "The Castle in the Stars",
    "The Castle in the Clouds",
    "The Ugly Duckling",
    "Sky Island School",
    "The Underground City",
    "The Underground Cave",
    "The Underground Lake",
    "The Underground River",
    "The Underground Forest",
    "The Underground Mountain",
    "The Underground Desert",
    "The Underground Jungle",
    "The Underground Ocean",
    "The Underground Volcano",
    "The Snow Prince",
    "The Snow Elf",
    "The Snow Dragon",
    "The Snow Monster",
    "The Snow Giant",
    "The Snow Wizard",
    "The Snow Fairy",
    "Ghost Bathhouse",
    "Ghost Restaurant",
    "Ghost Bakery",
    "Ghost Cafe",
    "Dragons Love Advocados",
    "Dragons Love Tacos",
    "Dragons Love Pizza",
    "Dragons Love Ice Cream",
    "Dragons Love Burgers",
    "Dragons Love Burritos",
    "Dragons Love Sushi",
    "Dragons Love Ramen",
    "Dragons Love Waffles",
    "Dragons Love Pancakes",
    "Dragons Love Donuts",
    "If you Give a Dragon a Cookie",
    "If you Give a Frog a Fly",
    "If you Give a Cat a Cupcake",
    "If you Give a Dog a Donut",
    "If you Give a Bear a Brownie",
    "If you Give a Unicorn a Cookie",
    "If you Give a Monster a Muffin",
    "If you Give a Witch a Waffle",
    "If you Give a Wizard a Waffle",
    "If you Give a Fairy a Fudge",
    "If you Give a Ghost a Grilled Cheese",
    "If you Give a Vampire a Veggie Burger",
    "If you Give a Werewolf a Waffle",
    "If you Give a Zombie a Zucchini",
    "If you Give a Robot a Raspberry",
    "If you Give a Pegasus a Pancake",
    "If you Give a Mermaid a Muffin",
    "If you Give a Bigfoot a Brownie",
    "If you Give a Yeti a Yogurt",
    "If you Give a Cyclops a Cupcake",
    "If you Give a Goblin a Grilled Cheese",
    "If you Give a Troll a Taco",
    "Advocado Toast",
    "Advocado Family",
    "Advocado Friends",
    "Advocado School",
    "Advocado Fashion",
    "Advocado Pets",
    "Advocado Food",
    "Advocado Vacations",
    "Advocado Sports",
    "Advocado Games",
    "Advocado Music",
    "Advocado Art",
    "Advocado Ice Cream",
    "Advocados in Space",
    "Advocados in the Ocean",
    "Advocados in the Forest",
    "Advocados in the Mountains",
    "The Great Avocado",
    "Advocado Land",
    "Advocado World",
    "Advocado Island",
    "Advocado City",
    "Advocados in New York",
    "Advocados in Paris",
    "Advocados in Tokyo",
    "Advocados in London",
    "Advocados in Rome",
    "Dinosaur Advocado",
    "Dragon Advocado",
    "Space Cat",
    "Wizard Cat",
    "Fairy Cat",
    "Elf Cat",
    "Bigfoot Cat",
    "Yeti Cat",
    "Mermaid Cat",
    "Unicorn Cat",
    "Pegasus Cat",
    "Superhero Cat",
    "Princess Cat",
    "Prince Cat",
    "Taco Cat",
    "Pizza Cat",
    "Secret Agent Cat",
    "Spy Cat",
    "Ninja Cat",
    "Pirate Cat",
    "The Avocado King",
    "The Avocado Queen",
    "The Avocado Prince",
    "The Avocado Princess",
    "The Avocado Knight",
    "The Avocado Warrior",
    "The Avocado Wizard",
    "The Avocado Witch",
    "The Avocado Fairy",
    "The Avocado Elf",
    "The Avocado Dragon",
    "The Avocado Monster",
    "The Avocado Robot",
    "The Avocado Vampire",
    "The Great Dragon Spirit",
    "The Great River Spirit",
    "The Great Forest Spirit",
    "The Great Mountain Spirit",
    "The Great Ocean Spirit",
    "The Great Sky Spirit",
    "The Great Earth Spirit",
    "The Great Fire Spirit",
    "The Great Water Spirit",
    "The Great Wind Spirit",
    "The Great Thunder Spirit",
    "The Great Lightning Spirit",
    "The Great Ice Spirit",
    "The Great Snow Spirit",
    "The Great Rain Spirit",
    "The Great Tree Spirit",
    "The Great Flower Spirit",
    "The Great Leaf Spirit",
    "The Great Fruit Spirit",
    "The Great Vegetable Spirit",
    "The Great Herb Spirit",
    "The Great Spice Spirit",
    "The Great Baby Spirit",
    "The Great Fairy Spirit",
    "The Great Winter Spirit",
    "The Great Spring Spirit",
    "The Great Summer Spirit",
    "The Great Fall Spirit",
    "The Great Halloween Spirit",
    "The Great Christmas Spirit",
    "The Valentine's Day Spirit",
    "The Great Cat Spirit",
    "The Great Dog Spirit",
    "The Great Pumpkin",
    "The Great Turkey",
    "The Great Reindeer",
    "The Great Snowman",
    "Monster Company",
    "Monster School",
    "Monster Family",
    "Monster Friends",
    "Monster Party",
    "Monster Parade",
    "Monster Dance",
    "Monster Movies",
    "Monster Books",
    "Monster Sports",
    "Monster Games",
    "Monster Music",
    "Monster History",
    "Monster Fashion",
    "Monster Christmas",
    "Monster Thanksgiving",
    "Monster Valentine's Day",
    "Monster Easter",
    "Monster Fourth of July",
    "Monster St. Patrick's Day",
    "Monster Mother's Day",
    "Monster Father's Day",
    "Monster Birthday",
    "Wizard Company",
    "Wizard Friends",
    "Wizard Party",
    "Wizard Parade",
    "Wizard Dance",
    "Wizard Games",
    "Wizard Music",
    "Wizard Books",
    "Wizard Art",
    "Wizard History",
    "Wizard Pets",
    "Wizard Food",
    "Wizard Fashion",
    "Wizard Vacations",
    "Wizard Sports",
    "Wizard Training",
    "Wizard Camping",
    "Wizard Campfire",
    "Wizard Stories",
    "Wizard Hiking",
    "Wizard Family",
    "Working Wizards",
    "Wizard Halloween",
    "Wizard Christmas",
    "Wizard Valentine's Day",
    "Wizard Easter",
    "Halloween Campfire",
    "Ghost School",
    "Ghost Campfire",
    "The Friendly Ghost",
    "The Friendly Witch",
    "The Friendly Vampire",
    "The Friendly Werewolf",
    "The Friendly Dragon",
    "The Friendly Unicorn",
    "The Friendly Monster",
    "The Friendly Robot",
    "Superhero Family",
    "Superhero Friends",
    "Superhero Team",
    "Superhero School",
    "Superhero Training",
    "Superhero Powers",
    "Superhero Villain",
    "Superhero Battle",
    "Superhero Adventure",
    "Superhero Mission",
    "Superhero Secret",
    "Superhero Origin",
    "Superhero Costume",
    "The Superhero Lair",
    "Superhero Candy",
    "Superhero Headquarters",
    "Superhero City",
    "Superhero Fashion",
    "Superhero Food",
    "Superhero Pets",
    "Superhero Vacation",
    "Superhero Sports",
    "Superhero Games",
    "Superman's Favorite Music",
    "Superhero Movies",
    "Superhero Halloween",
    "Superhero Christmas",
    "Superhero Valentine's Day",
    "Superhero Easter",
    "Superhero Campfire",
    "Superhero Camping",
    "Jack and the Beanstalk",
    "The Three Little Pigs",
    "The Little Red Hen",
    "The Gingerbread Man",
    "The Tortoise and the Hare",
    "The Magic Lamp",
    "The Magic Carpet",
    "The Magic Mirror",
    "The Magic Wand",
    "The Magic Potion",
    "The Magic Spell",
    "The Magic Book",
    "The Magic Key",
    "The Magic Door",
    "The Magic Window",
    "Magic Candy",
    "Rapunzel's Tower",
    "Cinderella's Castle",
    "Snow White's Cottage",
    "Sleeping Beauty's Castle",
    "The Big Bad Wolf",
    "The Evil Queen",
    "The Wicked Witch",
    "The Mad Hatter",
    "The White Rabbit",
    "The Cheshire Cat",
    "The Great Bake Off",
    "Faiy Tale Village",
    "Fairy Tale Forest",
    "Fairy Tale Castle",
    "Fairy Tale Kingdom",
    "Fairy Tale School",
    "Fairy Tale Friends",
    "Fairy Tale Family",
    "Fairy Tale Candy",
    "Fairy Tale Pets",
    "Fairy Tale Fashion",
    "Fairy Tale Food",
    "Fairy Tale Music",
    "Fairy Tale Movies",
    "Fairy Tale Books",
    "Fairy Tale Games",
    "Fairy Tale Art",
    "Fairy Tale History",
    "Fairy Tale Sports",
    "Fairy Tale Vacations",
    "Fairy Tale Adventures",
    "Fairy Tale Secrets",
    "Fairy Tale Magic",
    "Fairy Tale Spells",
    "Fairy Tale Potions",
    "Fairy Tale Curses",
    "Fairy Tale Christmas",
    "Fairy Tale Valentine's Day",
    "Fairy Tale Easter",
    "Princess School",
    "Princess Friends",
    "Princess Fashion",
    "Princess Music",
    "Princess Movies",
    "Princess Books",
    "Princess Games",
    "Princess Art",
    "Princess Candy",
    "Princess Sports",
    "Princess Vacations",
    "Princess Adventures",
    "Princess Campfire",
    "Princess Valentines Day",
    "Princess Easter",
    "Princess Halloween",
    "Princess Christmas",
    "Princess Castle",
    "Princess Train",
    "Princess Spirits",
    "Princess Delivery Service",
    "Princess Bakery",
    "My Neighbor is a Princess",
    "Princess in London",
    "Princess in Paris",
    "Princess in New York",
    "Princess in Tokyo",
    "Princess in Rome",
    "Princess in Venice",
    "Chef School",
    "Chef Friends",
    "Chef Family",
    "Chef Restaurant",
    "Friendly Robot",
    "The Responsible Robot",
    "The Helpful Robot",
    "The Caring Robot",
    "The Kind Robot",
    "The Brave Robot",
    "Robot Friends",
    "The Christmas Cat",
    "The Christmas Dog",
    "The Christmas Elf",
    "The Christmas Fairy",
    "The Christmas Panda",
    "The Christmas Rabbit",
    "The Christmas Racoons",
    "The Christmas Reindeer",
    "The Christmas Snowman",
    "The Christmas Star",
    "The Christmas Tree",
    "The Christmas Present",
    "The Christmas Stocking",
    "The Christmas Wreath",
    "The Christmas Lights",
    "The Christmas Cookies",
    "The Christmas Spirit",
    "The Christmas Miracle",
    "The Christmas Wish",
    "The Christmas Dream",
    "The Christmas Magic",
    "The Christmas Campfire",
    "The Halloween Racoon",
    "Halloween Town",
    "Halloween City",
    "Halloween School",
    "Halloween Academy",
    "Mermaid School",
    "Mermaid Academy",
    "Mermaid Friends",
    "Mermaid Family",
    "Mermaid Fashion",
    "Mermaid Music",
    "Mermaid Movies",
    "Mermaid Books",
    "Mermaid Games",
    "Mermaid Art",
    "Mermaid Sports",
    "Mermaid Vacations",
    "Mermaid Adventures",
    "Mermaid Christmas",
    "Mermaid Valentine's Day",
    "Mermaid Easter",
    "Mermaid Hotel",
    "Mermaid Spa",
    "Mermaid Land",
    "Mermaid Castle",
    "Mermaid Train",
    "Mermaid Spirits",
    "Mermaid Delivery Service",
    "Mermaid Bakery",
    "My Neighbor is a Mermaid",
    "Mermaids in Sea Planes",
    "Mermaids in London",
    "Mermaids in Paris",
    "Mermaids in New York",
    "Mermaids in Tokyo",
    "Mermaids in Rome",
    "Mermaids in Venice",
    "Dinosaur School",
    "Dinosaur Family",
    "Dinosaur Sports",
    "Dinosaur Fashion",
    "Dinosaur Christmas",
    "Dinosaur Valentine's Day",
    "Dinosaur Easter",
    "Dinosaur Hotel",
    "Dinosaur Spa",
    "Dinosaur Campfire",
    "Driving School",
    "Spy School",
    "Spy Friends",
    "Spy Fashion",
    "Spy Sports",
    "Spy Adventures",
    "Spy Missions",
    "Spy Gadgets",
    "Spy Games",
    "Spy Land",
    "Spy Halloween",
    "Spy Christmas",
    "Spy Valentine's Day",
    "Spy Easter",
    "Spy Candy",
    "Spy Campfire",
    "Fantasy School",
    "Fantasy Friends",
    "Fantasy Family",
    "Fantasy Fashion",
    "Fantasy Sports",
    "Space Adventures",
    "Space Vacations",
    "Space Food",
    "Space Hotel",
    "Space Spa",
    "Roman School",
    "Roman Friends",
    "Roman Family",
    "Roman Fashion",
    "Roman Sports",
    "Medieval School",
    "Mythical School",
    "Demigod School",
    "Demigod Camp",
    "Wizards and Demigods",
    "Wizards and Dragons",
    "Wizards and Unicorns",
    "Wizards and Fairies",
    "Wizards and Elves",
    "Wizards and Giants",
    "Mummy Fashion",
    "Mummy School",
    "Mummy Friends",
    "Mummy Family",
    "Mummy Sports",
    "Mummy Adventures",
    "Mummy Secrets",
    "Mummy Vacations",
    "Mummy Food",
    "Mummy Hotel",
    "Mummy Spa",
    "Mummy Christmas",
    "Werewolf School",
    "Werewolf Friends",
    "Werewolf Family",
    "Werewolf Fashion",
    "Werewolf Land",
    "Werewolf Christmas",
    "Werewolf Hotel",
    "Werewolf Spa",
    "Werewolf Castle",
    "Werewolf Train",
    "Werewolf Spirits",
    "Werewolf Delivery Service",
    "Werewolf Bakery",
    "Werewolf Campfire",
    "My Neighbor is a Werewolf",
    "Werewolves in London",
    "Werewolves in Paris",
    "Werewolves in New York",
    "Werewolves in Tokyo",
    "Werewolves in Rome",
    "Werewolves in Venice",
    "Wild Things School",
    "Wild Things Friends",
    "Wild Things Family",
    "Wild Things Fashion",
    "Wild Things Sports",
    "Wild Things Adventures",
    "Wild Things Secrets",
    "Wild Things History",
    "Wild Things Vacations",
    "Wild Things Food",
    "Wild Things Music",
    "Wild Things Movies",
    "Wild Things Books",
    "Wild Things Games",
    "Wild Things Art",
    "Wild Things Land",
    "Wild Things Hotel",
    "Wild Things Spa",
    "Wild Things Christmas",
    "Wild Things Valentine's Day",
    "Wild Thing Castle",
    "Wild Thing Train",
    "Wild Thing Spirits",
    "Wild Thing Delivery Service",
    "Wild Thing Bakery",
    "Wild Thing Campfire",
    "My Neighbor is a Wild Thing",
    "Wild Things in London",
    "Wild Things in Paris",
    "Wild Things in New York",
    "Wild Things in Tokyo",
    "Wild Things in Rome",
    "Wild Things in Venice",
    "Warlock School",
    "Warlock Friends",
    "Wonerland School",
    "Wonderland Friends",
    "Wonderland Family",
    "Wonderland Fashion",
    "Wonderland Spa",
    "Wonderland Christmas",
    "Wonderland Valentine's Day",
    "Wonderland Easter",
    "Wonderland Candy",
    "Dragon School",
    "Dragon Friends",
    "Dragon Family",
    "Dragon Fashion",
    "Dragon Sports",
    "Dragon Adventures",
    "Dragon Secrets",
    "Dragon History",
    "Dragon Vacations",
    "Dragon Food",
    "Dragon Music",
    "Dragon Hotel",
    "Dragon Spa",
    "Dragon Candy",
    "Dragon Christmas",
    "Dragon Halloween",
    "Dragon Valentine's Day",
    "Dragon Castle",
    "Dragon Train",
    "Dragon Spirits",
    "Dragon Delivery Service",
    "Dragon Bakery",
    "Dragon Campfire",
    "My Neighbor is a Dragon",
    "Dragons in London",
    "Dragons in Paris",
    "Dragons in New York",
    "Dragons in Tokyo",
    "Dragons in Rome",
    "Dragons in Venice",
    "Squirrel School",
    "Squirrel Friends",
    "Squirrel Family",
    "Squirrel Fashion",
    "Squirrel Hotel",
    "Unicorn School",
    "Unicorn Friends",
    "Unicorn Family",
    "Unicorn Fashion",
    "Unicorn Sports",
    "Unicorn Adventures",
    "Unicorn Secrets",
    "Unicorn History",
    "Unicorn Vacations",
    "Unicorn Food",
    "Unicorn Hotel",
    "Unicorn Spa",
    "Unicorn Christmas",
    "Unicorn Halloween",
    "Unicorn Valentine's Day",
    "Unicorn Castle",
    "Unicorn Train",
    "Unicorn Spirits",
    "Unicorn Delivery Service",
    "Unicorn Bakery",
    "Unicorn Campfire",
    "My Neighbor is a Unicorn",
    "Unicorns in London",
    "Unicorns in Paris",
    "Unicorns in New York",
    "Unicorns in Tokyo",
    "Unicorns in Rome",
    "Unicorns in Venice",
    "Dog Friends",
    "Dog Family",
    "Dog Hotel",
    "Dog Spa",
    "Dog Vacations",
    "Super Dogs",
    "Cat Friends",
    "Cat Family",
    "Cat Vacations",
    "Cat Hotel",
    "Cat Spa",
    "A Cat Christmas",
    "Super Cats",
    "8bit School",
    "8bit Friends",
    "8bit Family",
    "8bit Hotel",
    "8bit Spa",
    "8bit Christmas",
    "8bit Halloween",
    "8bit Valentine's Day",
    "8bit Candy",
    "Pixel School",
    "Pixel Friends",
    "Pixel Family",
    "Pixel Hotel",
    "Pixel Spa",
    "Pixel Christmas",
    "Pixel Halloween",
    "Pixel Valentine's Day",
    "Pixel Candy",
    "Pixel Train",
    "Nature School",
    "Nature Academy",
    "Nature Friends",
    "Nature Family",
    "Nature Fashion",
    "Nature Sports",
    "Nature Adventures",
    "Nature Secrets",
    "Camping School",
    "Camping Friends",
    "Camping Family",
    "Camping Fashion",
    "Camping Christmas",
    "Camping Halloween",
    "Camping Spirits",
    "Camping Delivery Service",
    "Campground Academy",
    "Campground Friends",
    "Campground Family",
    "Campground Fashion",
    "Campground Sports",
    "Campground Adventures",
    "Campground Secrets",
    "Campground Christmas",
    "Campground Halloween",
    "Campground Spirits",
    "Campground Delivery Service",
    "Campground Bakery",
    "Forest School",
    "Forest Friends",
    "Forest Family",
    "Forest Fashion",
    "Forest Sports",
    "Forest Adventures",
    "Forest Secrets",
    "Forest Christmas",
    "Forest Halloween",
    "Forest Spirits",
    "Forest Delivery Service",
    "Forest Bakery",
    "Forests in London",
    "Forests in Paris",
    "Forests in New York",
    "Forests in Tokyo",
    "Jungle School",
    "Jungle Friends",
    "Jungle Family",
    "Jungle Fashion",
    "Jungle Sports",
    "Jungle Adventures",
    "Villian School",
    "Villian Friends",
    "Villian Family",
    "Villian Fashion",
    "Villian Sports",
    "Villian Adventures",
    "Villian Hotel",
    "Villian Spa",
    "Villian Christmas",
    "Villian Valentine's Day",
    "Villian Candy",
    "Villian Train",
    "Villian Delivery Service",
    "Villian Bakery",
    "Villian Campfire",
    "Thief School",
    "Thief Friends",
    "Thief Family",
    "Thief Fashion",
    "Thief Hotel",
    "Thief Spa",
    "Thief Christmas",
    "Thief Valentine's Day",
    "Thief Candy",
    "Thief Castle",
    "Thief Train",
    "Thief Spirits",
    "Thief Delivery Service",
    "Thief Bakery",
    "Thief Campfire",
    "My Neighbor is a Thief",
    "Thieves in London",
    "Thieves in Paris",
    "Thieves in New York",
    "Thieves in Tokyo",
    "Thieves in Rome",
    "Thieves in Venice",
    "Ninja School",
    "Ninja Friends",
    "Ninja Family",
    "Ninja Traditions",
    "Ninja Hotel",
    "Ninja Spa",
    "Ninja Christmas",
    "Ninja Valentine's Day",
    "Ninja Candy",
    "Ninja Castle",
    "Ninja Train",
    "Ninja Spirits",
    "Ninja Delivery Service",
    "Ninja Bakery",
    "Ninja Campfire",
    "My Neighbor is a Ninja",
    "Ninjas in London",
    "Ninjas in Paris",
    "Ninjas in New York",
    "Ninjas in Tokyo",
    "Ninjas in Rome",
    "Ninjas in Venice",
    "Pirate School",
    "Pirate Friends",
    "Pirate Family",
    "Pirate Fashion",
    "Pirate Adventures",
    "Pirate Secrets",
    "Pirate History",
    "Pirate Company",
    "Pirate Vacations",
    "Pirate Food",
    "Pirate Music",
    "Pirate Movies",
    "Pirate Books",
    "Pirate Games",
    "Pirate Art",
    "Pirate Ships",
    "Pirate Treasure",
    "Pirate Island",
    "Pirate Crew",
    "Pirate Captain",
    "Pirate Parrots",
    "Pirate Monkeys",
    "Pirate Swords",
    "Pirate Hooks",
    "Pirate Hotel",
    "Pirate Spa",
    "Pirate Christmas",
    "Pirate Valentine's Day",
    "Pirate Castle",
    "Pirate Train",
    "Pirate Spirits",
    "Pirate Delivery Service",
    "Pirate Bakery",
    "Pirate Campfire",
    "My Neighbor is a Pirate",
    "Pirates in Sea Planes",
    "Pirates in London",
    "Pirates in Paris",
    "Pirates in New York",
    "Pirates in Tokyo",
    "Pirates in Rome",
    "Pirates in Venice",
    "Pirate Candy",
    "Gnome School",
    "Gnome Friends",
    "Gnome Family",
    "Gnome Fashion",
    "Gnome Hotel",
    "Gnome Spa",
    "Gnome Christmas",
    "Gnome Halloween",
    "Gnome Valentine's Day",
    "Gnome Candy",
    "Gnome Castle",
    "Gnome Train",
    "Gnome Spirits",
    "Gnome Delivery Service",
    "Gnome Bakery",
    "Gnome Campfire",
    "My Neighbor is a Gnome",
    "Gnomes in London",
    "Gnomes in Paris",
    "Gnomes in New York",
    "Gnomes in Tokyo",
    "Gnomes in Rome",
    "Gnomes in Venice",
    "Goblin Train",
    "Goblin Christmas",
    "Goblin Delivery Service",
    "Goblin Bakery",
    "My Neighbor is a Goblin",
    "Goblins in London",
    "Goblins in Paris",
    "Goblins in New York",
    "Goblins in Tokyo",
    "Goblins in Rome",
    "Goblins in Venice",
    "Witch Company",
    "Witch Friends",
    "Witch Party",
    "Witch Parade",
    "Witch Dance",
    "Witch Games",
    "Witch Music",
    "Witch Books",
    "Witch Movies",
    "Witch Art",
    "Witch History",
    "Witch Pets",
    "Witch Food",
    "Witch Fashion",
    "Witch Vacations",
    "Witch Sports",
    "Witch Training",
    "Witch Camping",
    "Witch Campfire",
    "Witch Stories",
    "Witch Hiking",
    "Witch Family",
    "Witches Incorporated",
    "Witch Cafe",
    "Witch Hotel",
    "Witch Spa",
    "Witch Bathhouse",
    "Witch Christmas",
    "Witch Valentine's Day",
    "Witch Candy",
    "Witch Castle",
    "Witch Train",
    "Witch Spirits",
    "Witch Delivery Service",
    "Witch Bakery",
    "My Neighbor is a Witch",
    "Witches in Sea Planes",
    "Witches in London",
    "Witches in Paris",
    "Witches in New York",
    "Witches in Tokyo",
    "Witches in Rome",
    "Witches in Venice",
    "Cozy Village",
    "Cozy Town",
    "Cozy City",
    "Cozy School",
    "Cozy Cafe",
    "Cozy Bakery",
    "Cozy Witches",
    "Cozy Campfire",
    "Cozy Camping",
    "Cozy Cottage",
    "Cozy Cabin",
    "Cozy Forest",
    "Cozy Lake",
    "Cozy River",
    "Cozy Ocean",
    "Cozy Beach",
    "Cozy Mountain",
    "Cozy Valley",
    "Cozy Wizards",
    "Cozy Wizard Academy",
    "Cozy Witch Academy",
    "The Great School Adventure",
    "Lunchtime Escapades",
    "Mystery of the Missing Homework",
    "Science Fair Frenzy",
    "The Principal's Secret",
    "Class Clown Chaos",
    "Field Trip Fun",
    "The Art Class Surprise",
    "Overheard in the Hallway",
    "The Spelling Bee Showdown",
    "Recess Revelations",
    "The Substitute Teacher Takes Over",
    "The Ultimate School Play",
    "Cafeteria Capers",
    "Gym Class Giggles",
    "Yearbook Drama",
    "The Great Book Report Heist",
    "Choir Concert Catastrophe",
    "A Day in Detention",
    "The Backpack Conundrum",
    "Math Class Mischief",
    "Picture Day Panic",
    "Famous School Legends",
    "History Class Heroes",
    "The Library Whisperer",
    "Back-to-School Bash",
    "The Secret Clubhouse",
    "The Great Locker Mystery",
    "Music Room Madness",
    "Homeroom Hijinks",
    "Sneaky Study Hall",
    "Art Show Antics",
    "Talent Show Triumphant",
    "Science Lab Surprise",
    "Teacher Appreciation Adventures",
    "School Spirit Spectacle",
    "The Playground Puzzler",
    "Creative Writing Wonders",
    "The Homework Disaster",
    "Funny School Announcements",
    "Geography Class Giggles",
    "Debate Team Dilemmas",
    "The Lost and Found Fiasco",
    "School Dance Drama",
    "The Famous Field Day",
    "School Bus Banter",
    "Classroom Clean-up Quest",
    "Storytime Secrets",
    "The Great Pencil War",
    "School Supplies Saga",
    "The Busy Office Buzz",
    "Chef's Culinary Caper",
    "The Detective's Big Break",
    "A Day at the Fire Station",
    "The Secret Life of Teachers",
    "Adventure in the Hospital Halls",
    "The Astronaut's Amazing Mission",
    "Construction Site Surprises",
    "The Zookeeper's Zany Day",
    "The Journalist's Journey",
    "Behind the Scenes at the Theater",
    "The Accountant's Last-Minute Crunch",
    "Mail Carrier Mysteries",
    "The Librarian's Secret Book",
    "Adventures of the Traveling Salesperson",
    "The Pilot's Pre-Flight Plan",
    "The Lifeguard's Rescue Adventure",
    "At the Artist's Studio",
    "The Barber's Bizarre Style",
    "The Archaeologist's Hidden Treasure",
    "The Mechanic's Magical Fix",
    "The Gardener's Growing Pains",
    "The Futuristic Tech Start-up",
    "A Day in the Life of a Sea Captain",
    "The Fashion Designer's Daring Runway",
    "The Veterinarian's Furry Friends",
    "The Lawyer's Legal Labyrinth",
    "The DJ's Dance Floor Dilemma",
    "The Taxi Driver's Tall Tales",
    "The Plumber's Pipe Dream",
    "The Movie Director's Big Debut",
    "The Architect's Ambitious Plan",
    "The Farmer's Friendly Farm",
    "The Rock Star's Road Trip",
    "The Carpenter's Crafty Creations",
    "The Nurse's Night Shift",
    "The Spy's Secret Mission",
    "The Retail Associate's Remarkable Sale",
    "The Flight Attendant's Sky-High Adventure",
    "The Magician's Mystifying Performance",
    "The Scientist's Groundbreaking Experiment",
    "The Bus Driver's Daily Commute",
    "The IT Technician's Technology Troubles",
    "The Waiter's Whirlwind Shift",
    "The Personal Trainer's Fitness Challenge",
    "The Photographer's Picture-Perfect Day",
    "The Professor's Peculiar Lecture",
    "The Pet Sitter's Playful Pals",
    "The Baker's Sweet Surprise",
    "The Police Officer's Patrol",
    "The Case of the Missing Necklace",
    "Mystery at the Midnight Museum",
    "The Secret of the Haunted Manor",
    "The Detective's Double Puzzle",
    "The Vanishing Villain",
    "Enigma on the Train",
    "The Whispering Shadows",
    "The Great Treasure Hunt Mystery",
    "The Phantom Thief's Riddle",
    "The Cryptic Caves Conundrum",
    "The Curious Case of the Disappearing Diary",
    "The Lighthouse Lurker",
    "Mystery at the Masquerade Ball",
    "The Puzzle of the Painted Portrait",
    "The Secret Passage Plot",
    "The Clue in the Opera House",
    "The Case of the Stolen Gemstone",
    "The Enchanted Forest Enigma",
    "The Mystery of the Lost Map",
    "The Hidden Room Riddle",
    "The Mysterious Message in a Bottle",
    "The Disappearing Dessert Dilemma",
    "The Case of the Sleeping Spy",
    "The Clock Tower Conspiracy",
    "The Phantom Footprint Phenomenon",
    "The Secret of the Silver Key",
    "The Ghost Ship Mystery",
    "The Case of the Whispering Walls",
    "The Mystery of the Masked Magician",
    "The Enigma of the Eerie Eclipse",
    "The Shadow in the Secret Garden",
    "The Mystery of the Forgotten Lab",
    "The Case of the Enigmatic Egg",
    "The Library Ghost's Secret",
    "The Curious Case of the Crooked Crown",
    "The Sulky Soda Pop Mystery",
    "The Cryptic Circus Conundrum",
    "The Mystery of the Hidden Harbor",
    "The Puzzle of the Perilous Peak",
    "The Fairfield Phantom",
    "The Mystery of the Moonlit Maze",
    "The Case of the Vanishing Violin",
    "The Specter at Sunset Lake",
    "The Riddle of the Runaway Robot",
    "The Secret Safari Scandal",
    "The Whispering Windmill Mystery",
    "The Case of the Giggling Ghost",
    "The Mystery of the Golden Goblet",
    "The Enigma of the Emerald Eye",
    "The Hilarious Hiccups Incident",
    "Pranks at the Picnic",
    "The Silly Sandwich Saga",
    "The Great Jokes Jamboree",
    "Clowning Around in the Classroom",
    "The Comical Coffee Catastrophe",
    "Laughs at the Library",
    "The Wacky Wardrobe Malfunction",
    "The Puns at the Park",
    "The Giggle-Filled Game Night",
    "Comedy at the Carnival",
    "The Ridiculous Road Trip",
    "The Laughable Lunch Mystery",
    "The Silly Slumber Party",
    "The Funky Family Reunion",
    "The Comedy Club Capers",
    "Joke-tastic Jungle Adventure",
    "The Zany Zoo Escapade",
    "The Amusing Amusement Park Antics",
    "The Quirky Quest for Cookies",
    "The Cracking-Up Cooking Class",
    "The Wondrously Wacky Wedding",
    "Comedy on the Camping Trip",
    "The Ridiculous Roller Coaster Ride",
    "The Giggly Grocery Store Grab",
    "The Zippy Zoo Visit",
    "The Laugh-Out-Loud Library Adventure",
    "The Clown’s Crazy Circus",
    "The Chuckling Classroom Caper",
    "The Hysterical House Party",
    "The Foolish Fishing Fiasco",
    "The Playful Pet Parade",
    "The Joke-Filled Journey",
    "The Silly Science Experiment",
    "The Outrageous Office Outing",
    "The Hysterical Holiday Hijinks",
    "The Nonsensical Neighborhood Hunt",
    "The Funny Farm Adventure",
    "The Laughable Lemonade Stand",
    "The Chucklesome Camping Chaos",
    "The Rib-Tickling Race Day",
    "The Gigglesome Garden Gnome",
    "The Whimsical Water Balloon Battle",
    "The Silly School Play",
    "The Nutty Ninja Quest",
    "The Bonkers Beach Bash",
    "The Hysterical Homecoming",
    "Jokers on the Jungle Gym",
    "The Comedy Club Catastrophe",
    "The Bananas Birthday Bash",
  ];
  const len = titles.length;
  return titles[getRandomInt(len)];
};
